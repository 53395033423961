var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3 min-w-0 w-full"},[_c('div',{staticClass:"display-1"},[_vm._v("Välittäjät")]),_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',[_c('v-spacer'),(_vm.isAuthorized('realtor', 'create'))?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"info"},on:{"click":function($event){_vm.edit = false;
          _vm.realtorDialog = true;}}},[_vm._v("Uusi välittäjä")]):_vm._e()],1),_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Hae kaikista kentistä...","single-line":"","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi()},"click:append":function($event){return _vm.getDataFromApi()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col')],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.realtors,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.clientId",fn:function(ref){
          var item = ref.item;
return [_c('span',[_c('strong',[_vm._v("# "+_vm._s(item.clientId))])])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"action-grid-container"},[(_vm.isAuthorized('realtor', 'edit'))?_c('v-icon',{attrs:{"title":"Muokkaa","small":""},on:{"click":function($event){_vm.edit = true;
              _vm.setCurrentRealtor(item);
              _vm.realtorDialog = true;}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.isAuthorized('realtor', 'delete'))?_c('v-icon',{attrs:{"title":"Poista","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei välittäjiä")])]},proxy:true}],null,true)})],1),_c('realtor-form',{attrs:{"edit":_vm.edit},model:{value:(_vm.realtorDialog),callback:function ($$v) {_vm.realtorDialog=$$v},expression:"realtorDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }