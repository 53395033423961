<template>
  <v-dialog v-model="dialog" width="400">
    <v-card>
      <v-card-title> {{ title }} </v-card-title>

      <v-card-text class="mt-2">
        <v-form ref="form">
          <v-text-field label="Nimi" v-model="name" :rules="required" outlined dense></v-text-field>
          <v-text-field
            label="Email"
            v-model.trim="email"
            :rules="required.concat(emailRules)"
            outlined
            dense
          ></v-text-field>
          <v-text-field label="Puhelin" v-model="phone" outlined dense></v-text-field>

          <!-- <v-text-field label="Osoite" v-model="address" outlined dense></v-text-field>
          <v-text-field label="Postinumero" v-model="zipCode" outlined dense></v-text-field>
          <v-text-field label="Kaupunki" v-model="city" outlined dense></v-text-field> -->
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click.prevent="submit">{{ btnText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  props: {
    value: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
  },

  mixins: [mixins],

  data(vm) {
    return {
      required: [(v) => !!v || "Pakollinen kenttä"],
      emailRules: [(v) => vm.testEmail(v)],
    };
  },

  computed: {
    ...mapState("realtor", ["currentRealtor"]),
    ...mapState("contract", ["activeRentalContracts"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) this.$refs.form.reset();
        this.$emit("input", value);
      },
    },
    btnText() {
      if (this.edit) {
        return "Tallenna";
      } else {
        return "Luo";
      }
    },
    title() {
      if (this.edit) {
        return "Muokkaa välittäjää";
      } else {
        return "Luo välittäjä";
      }
    },
    name: {
      get() {
        return this.currentRealtor.name;
      },
      set(val) {
        this.setFieldValue({ val, field: "name" });
      },
    },
    email: {
      get() {
        return this.currentRealtor.email;
      },
      set(val) {
        this.setFieldValue({ val, field: "email" });
      },
    },
    phone: {
      get() {
        return this.currentRealtor.phone;
      },
      set(val) {
        this.setFieldValue({ val, field: "phone" });
      },
    },
  },

  methods: {
    ...mapActions("realtor", ["createRealtor", "updateRealtor"]),
    ...mapMutations("realtor", ["setFieldValue"]),

    testEmail(val) {
      if (!val) {
        return true;
      }
      return /.+@.+\..+/.test(val) || "Email ei ole oikeassa muodossa";
    },

    async submit() {
      if (this.$refs.form.validate()) {
        if (this.edit) {
          try {
            await this.updateRealtor({ ...this.currentRealtor });
            this.showPopup("Välittäjä päivitetty", "success");
          } catch (err) {
            this.showPopup(err, "error");
          }
        } else {
          try {
            // Create
            await this.createRealtor({ ...this.currentRealtor });
            this.showPopup("Välittäjä luotu", "success");
            this.$refs.form.reset();
          } catch (err) {
            this.showPopup(err, "error");
          }
        }
      }
    },
  },
};
</script>

<style scoped></style>
